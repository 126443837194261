<template>
  <div ref="statByMethod" class="statByMethod">
    <div class="explain">
      <header><i class="el-icon-warning"></i> 数据说明</header>
      <ol>
        <li>
          线上售票人数和线上收入：完成在线支付的订单，不含退票/改签/售后退票订单。
        </li>
      </ol>
    </div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="发车时间：" class="code-time-range">
          <el-date-picker
            v-model="time"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            @change="selectTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="公司：">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="线路：">
          <el-select
            v-model="form.lineIds"
            multiple
            filterable
            collapse-tags
            clearable
            placeholder="请选择线路"
          >
            <el-option
              v-for="item in routeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否含取消接驾：">
          <el-select v-model="form.includingCancellation">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电召订票收现：">
          <el-select v-model="form.includingCallOrder">
            <el-option label="包含" :value="true"></el-option>
            <el-option label="不包含" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="padding-left: 50px">
          <el-button type="primary" size="small" @click="renderData"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            type="primary"
            size="small"
            @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="table-content">
      <div ref="tabs" class="tab">
        <el-tabs v-model="active" @tab-click="handleClick">
          <el-tab-pane label="按公司" name="1" />
          <el-tab-pane label="按线路" name="2" />
          <el-tab-pane label="按车辆" name="3" />
        </el-tabs>
      </div>
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :show-summary="true"
        :default-expand-all="true"
        :table-height="650"
        :summary-method="getSummaries"
        :tree-props="{ children: 'children' }"
      >
      </Table>
    </div>
  </div>
</template>

<script>
import {
  getCompanyTree,
  waysBuyTicketAPI,
  listByCompanyIdAPI,
  waysBuyTicketExportAPI,
} from "@/api/lib/api.js";
import moment from "moment";
export default {
  data() {
    return {
      form: {
        companyId: "",
        startDepartureDate: "",
        endDepartureDate: "",
        lineIds: [],
        includingCancellation: false,
        includingCallOrder: false,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const date = moment(new Date()).format("YYYY-MM-DD");
              const end = date + " 23:59:59";
              const start = date + " 00:00:00";
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = moment(new Date())
                .subtract(1, "days")
                .format("YYYY-MM-DD");
              const end = date + " 23:59:59";
              const start = date + " 00:00:00";
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近7天",
            onClick(picker) {
              const date1 = moment(new Date())
                .subtract(7, "days")
                .format("YYYY-MM-DD");
              const date2 = moment(new Date()).format("YYYY-MM-DD");
              const end = date2 + " 23:59:59";
              const start = date1 + " 00:00:00";
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近1个月",
            onClick(picker) {
              const date1 = moment(new Date())
                .subtract(1, "months")
                .format("YYYY-MM-DD");
              const date2 = moment(new Date()).format("YYYY-MM-DD");
              const end = date2 + " 23:59:59";
              const start = date1 + " 00:00:00";
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "本月",
            onClick(picker) {
              const date1 = moment(new Date())
                .startOf("month")
                .format("YYYY-MM-DD");
              const date2 = moment(new Date()).format("YYYY-MM-DD");
              const end = date2 + " 23:59:59";
              const start = date1 + " 00:00:00";
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "上月",
            onClick(picker) {
              const date = moment(new Date()).subtract(1, "months");
              const date1 = date.startOf("month").format("YYYY-MM-DD");
              const date2 = date.endOf("month").format("YYYY-MM-DD");
              const end = date2 + " 23:59:59";
              const start = date1 + " 00:00:00";
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      routeList: [],
      companyList: [],
      value: null,
      time: null,
      valuename: null,
      active: "1",
      data: null,
      tableData: [],
      titleName: [
        {
          title: "公司",
          props: "companyName",
          width: 200,
        },
        {
          title: "售票总人数",
          props: "ticketsTotal",
        },
        {
          title: "乘客购票数",
          props: "ticketsPassenger",
        },
        {
          title: "快客码购票数",
          props: "ticketsKKSmart",
        },
        {
          title: "后台购票数",
          props: "ticketsServer",
        },
        {
          title: "电召订票数",
          props: "ticketsCall",
        },
        {
          title: "乘客购票占比",
          props: "ticketsPassengerProportion",
          SpecialJudgment: (res) => res.toFixed(2) + "%",
        },
        {
          title: "快客码购票占比",
          props: "ticketsKKSmartProportion",
          SpecialJudgment: (res) => res.toFixed(2) + "%",
        },
        {
          title: "后台购票占比",
          props: "ticketsServerProportion",
          SpecialJudgment: (res) => res.toFixed(2) + "%",
        },
        {
          title: "电召订票占比",
          props: "ticketsCallProportion",
          SpecialJudgment: (res) => res.toFixed(2) + "%",
        },
      ],
    };
  },
  mounted() {
    const date = moment(new Date()).format("YYYY-MM-DD");
    this.time = [date + " 00:00:00", date + " 23:59:59"];
    this.form.endDepartureDate = this.time[1];
    this.form.startDepartureDate = this.time[0];
    this.getCompanyList();
    this.renderData();
    this.getLineList();
  },
  methods: {
    selectTime(val) {
      if (val) {
        this.form.endDepartureDate = val[1];
        this.form.startDepartureDate = val[0];
      } else {
        this.form.endDepartureDate = null;
        this.form.startDepartureDate = null;
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
          return;
        }
        if (index == 1) {
          const values = data.map((item) => item.ticketsTotal);
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr;
          }, 0);
        }
        if (index == 2) {
          const values = data.map((item) => item.ticketsPassenger);
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr;
          }, 0);
        }
        if (index == 3) {
          const values = data.map((item) => item.ticketsKKSmart);
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr;
          }, 0);
        }
        if (index == 4) {
          const values = data.map((item) => item.ticketsServer);
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr;
          }, 0);
        }
        if (index == 5) {
          const values = data.map((item) => item.ticketsCall);
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr;
          }, 0);
        }
      });
      if (!sums[1]) {
        sums[6] = "0.00%";
        sums[7] = "0.00%";
        sums[8] = "0.00%";
        sums[9] = "0.00%";
      } else {
        sums[6] = ((sums[2] / sums[1]) * 100).toFixed(2) + "%";
        sums[7] = ((sums[3] / sums[1]) * 100).toFixed(2) + "%";
        sums[8] = ((sums[4] / sums[1]) * 100).toFixed(2) + "%";
        sums[9] = ((sums[5] / sums[1]) * 100).toFixed(2) + "%";
      }
      return sums;
    },
    exportFun() {
      waysBuyTicketExportAPI(this.form).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "购票方式统计 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    renderData() {
      waysBuyTicketAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.data = res.data;
          this.handleClick();
        }
      });
    },
    // 企业id回显
    getGroupId(v) {
      this.form.companyId = v;
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 获取线路
    getLineList() {
      listByCompanyIdAPI(this.form.companyId).then((res) => {
        if (res.code === "SUCCESS") {
          this.routeList = res.data;
        }
      });
    },
    handleClick() {
      let obj = {};
      switch (this.active) {
        case "1":
          obj = {
            title: "公司",
            props: "companyName",
            width: 400,
          };
          this.tableData = this.data.itemCompany;
          break;
        case "2":
          obj = {
            title: "线路",
            props: "lineName",
            width: 400,
          };
          this.tableData = this.data.itemLine;
          break;
        case "3":
          obj = {
            title: "车牌号",
            props: "vehicleName",
            width: 200,
          };
          this.tableData = this.data.itemVehicle;
          break;
        default:
          break;
      }
      this.titleName.splice(0, 1, obj);
      this.tableData = this.tableData.map((e, index) => {
        e.id = index + "";
        if (e.lineRevenueSummary && e.lineRevenueSummary.length > 0) {
          e.lineRevenueSummary.map((i, seq) => {
            i.id = index + "n" + seq;
          });
        }
        return e;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.statByMethod {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .code-time-range {
    .el-date-editor {
      width: 13vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
  .table-content {
    margin-top: 16px;
    border: 1px solid #f3f3f3;
    padding: 16px;
    padding-top: 0;
    box-sizing: border-box;
  }
  /deep/.el-table__footer-wrapper {
    margin-top: 0;
  }
}
</style>
